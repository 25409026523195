var exports = {};

/* eslint-disable sort-keys */
exports = Object.freeze({
  // same as DOM DOCUMENT_POSITION_
  DISCONNECTED: 1,
  PRECEDING: 2,
  FOLLOWING: 4,
  CONTAINS: 8,
  CONTAINED_BY: 16
});
export default exports;